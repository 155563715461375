<template>
  <div class="bar-Char">
    <BarChart
      v-if="showChart"
      :chartdata="chartData"
      :options="options"
      style="min-height: 250px; height: 250px"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import statsProvider from '@/providers/stats'

export default {
  components: {
    BarChart: () => import('@/components/UI/Charts/BarChart'),
  },

  props: {
    locationId: {
      type: Number,
      default: 0,
    },

    meetingtypeId: {
      type: Number,
      default: 1,
    },

    widgetId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    let date = new Date()

    return {
      startDate: new Date(date.setDate(date.getDate() - 30)),
      endDate: new Date(),
      data: null,
      showChart: false,

      chartData: {
        labels: [],
        datasets: [],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: false,
          },
          y: {
            stacked: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  created() {
    this.getTransactionData()
  },

  mounted() {},

  methods: {
    getTransactionData() {
      statsProvider.methods
        .getTransactionActivity(
          this.$options.filters.dateObjectIsoDateString(this.startDate),
          this.$options.filters.dateObjectIsoDateString(this.endDate),
          this.channel.Id,
          this.locationId,
          this.widgetId,
          this.meetingtypeId
        )
        .then((response) => {
          if (response.status === 200) {
            this.data = response.data

            let labels = []
            let dataset1 = []
            let dataset2 = []

            for (let i = 0; i < this.data.length; i++) {
              let d = this.data[i]

              labels.push(this.$options.filters.dateObjectIsoDateString(d.Date))
              dataset1.push(d.NrOfSearch)
              dataset2.push(d.NrOfReservationCreated)
            }

            this.chartData.labels = labels
            this.chartData.datasets.push({
              label: 'Nr of search',
              data: dataset1,
              fill: false,
              borderColor: '#209CEE',
              backgroundColor: '#209CEE',
              borderWidth: 1,
            })
            this.chartData.datasets.push({
              label: 'Nr of reservation',
              data: dataset2,
              fill: false,
              borderColor: '#48C774',
              backgroundColor: '#48C774',
              borderWidth: 1,
            })
          }

          this.showChart = true
        })
        .catch((err) => {})
    },
  },
}
</script>

<style></style>
